@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_font";
@import "../../frontend/stylesheets/styles/_mixins";

.user-form__buttons {
  display: block;
  margin-top: 40px;

  .btn {
    display: block;
    width: 100%;
    box-sizing: border-box;

    &:first-child {
      margin-bottom: 10px;
    }
  }
}

@mixin mq {
  .user-form__buttons {

    .btn.btn-line,
    .btn.btn-line--disconnect,
    .btn.btn-line:disabled {
      padding: 10px 20px;
      height: auto;
      margin-bottom: 0;
    }

    width: 415px;
  }
}

@mixin mq {
  .user-form__buttons {
    display: flex;

    .btn {
      margin-left: 5px;
      margin-right: 5px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    width: 575px;
  }
}

@mixin mq {
  .user-form__buttons {
    display: block;
    margin-top: 40px;

    .btn {
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

@mixin mq {
  .user-form__buttons {
    display: flex;

    .btn {
      margin-left: 5px;
      margin-right: 5px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    width: 850px;
  }
}
