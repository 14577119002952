@import "../../stylesheets/styles/_variables";
@import "../../stylesheets/styles/_font";
@import "../../stylesheets/styles/_mixins";

.invitation-show {
  margin: 20px;

  .btn {
    width: 100%;
  }

  .description {}

  .invitation_url {
    input {
      width: 100%;
      padding: 4px;
      border: 1px solid black;
      background-color: $base-white;
    }

    .actions {
      margin-top: 8px;
    }
  }

  .invitation_qr_code {
    margin-top: 20px;
    text-align: center;
  }

  .back {
    margin-top: 20px;
  }
}
