$font-size-xs: 11px;
$font-size-s: 12px;
$font-size-m: 13px;
$font-size-l: 14px;
$font-size-xl: 15px;
$font-size-xxl: 16px;
$blue-txt: #0f667f;
$white: #fff;
$txt-dark: #1f1e1e;

.avenir {
  font-family: Avenir, sans-serif;
}
