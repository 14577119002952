@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_mixins";

.selling-information-list-item {
  cursor: pointer;

  a {
    text-decoration: none;
  }

  a:hover {
    color: $font-black;
  }

  .item-card__price-wrap {
    height: 2.5em;
  }

  .c-item-card__organization {
    margin-left: 5px;
    order: 2;
  }

  .c-item-card__time {
    order: 3;
  }

  .c-item-card__detail {
    font-size: 80%;
    color: $font-black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }

  .swiper-slide {
    display: inline-block;
    background-color: #000;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 100%;
    background-size: contain;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: -1%;
  }
}

@mixin mq {
  .selling-information-list-item {
    .c-item-card__supplementary {
      display: block;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .c-item-card__organization {
      margin-left: -3px;
      margin-bottom: 5px;
    }

    .c-item-card__area {
      float: left;
    }

    .c-item-card__time {
      float: right;
    }

    width: 480px;
  }
}

@mixin mq {
  .selling-information-list-item {
    .c-item-card__organization {
      margin: 0 0 5px;
    }

    width: 576px;
  }
}
