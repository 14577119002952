@import "variables";

.heading-01 {
  font-size: 20px;
  margin: 40px 0 0;
  text-align: center;
  border: none;
  font-weight: bold;
  &::before {
    content: none;
  }
}

.heading-02 {
  font-size: 16px;
  font-weight: bold;
}
.heading-02--large {
  @extend .heading-02;
  font-size: 20px;
}

.heading-03 {
  background-color: $primary-color;
  padding: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.heading-04 {
  background-color: #7e898e;
  color: #fff;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 15px;
}

@mixin mq  {
  .heading-01 {
    position: relative;
    text-align: left;
    font-size: 26px;
    margin: 60px 0 0;
    padding: 6px 0 6px 10px;
    border-left: 5px solid $font-black;
    span {
      position: relative;
      z-index: 1;
      background-color: #fafafa;
      padding-right: 20px;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $font-black;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 0;
    }
    width: 581px;
  }
}
