@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_mixins";

.item-list {
  .c-item-card {
    margin-bottom: 30px;
  }

  .c-item-card__status {
    &::before {
      top: -8px;
    }

    span {
      top: 16px;
    }
  }

  .load-more-items {
    margin: 20px 0;
    text-align: center;
  }
}
