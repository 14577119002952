$base-green: #0f667f;
$base-orange: #eb6536;
$base-white: #fff;
$blue-gray: #edf2f3;
$darkgrey: #7e898e;
$line-gray: #ebebeb;
$title-gray: #c0cdcf;
$border-gray: #eee;
$border-gray-dark: #dbdbdb;
$color-danger: #cb444a;
$font-black: #1f1e1e;
$primary-color: $base-green;
$secondary-color: $base-orange;
$form-border: #ced4da;

$blue: #0f667f !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #eb6536 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

