@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_mixins";

.item-list-item {
  cursor: pointer;

  a {
    text-decoration: none;
  }

  a:hover {
    color: $font-black;
  }

  .picture {
    span {
      display: inline-block;
      background-color: #000;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      padding-top: 100%;
      background-size: contain;
      border-radius: 4px 4px 0 0;
    }
  }

  .info {
    padding: 8px;

    .info-top {
      margin-bottom: 6px;
      font-size: 12px;

      span {
        overflow: hidden;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }

    .info-middle {
      margin-bottom: 6px;
      font-size: 12px;

      span {
        overflow: hidden;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }

    .info-bottom {
      margin-bottom: 6px;
      font-size: 12px;

      .price {
        font-family: Avenir, sans-serif;
        color: $base-orange;
        font-size: 19px;
        line-height: 24px;
      }
    }

    .info-tags {
      font-size: 12px;

      .tag--price-per-weight {
        background-color: rgb(196, 234, 138);
        color: black;
      }
    }
  }

  .c-item-card__organization {
    margin-left: 0;
  }

  .c-item-card-state {
    padding-bottom: 5px;
  }

  .c-item-card__time {
    order: 3;
  }

  .c-item-card__detail {
    font-size: 80%;
    color: $font-black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
}

@mixin mq {
  .item-list-item {
    .c-item-card-state {
      padding-bottom: 10px;
    }

    width: 576px;
  }
}
