@import "variables";
@import "./_mixins";

.user-icon {
  min-width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  mask-image: url(../images/profile_mask.svg);
}

.user-icon--small {
  @extend .user-icon;
  min-width: 40px;
  height: 40px;
}
