@import "variables";

.swiper-pagination-bullet-active {
  background-color: #333;
}
.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.6);
  top: 48%;
  width: 50px;
  height: 0;
  padding-top: 50px;
  display: block;
  overflow: hidden;
  background-image: none;
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-top: 2px solid $font-black;
    border-right: 2px solid $font-black;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.swiper-button-next {
  right: 0;
  border-radius: 3px 0 0 3px;
  &::after {
    transform: rotate(45deg);
    right: 20px;
  }
}
.swiper-button-prev {
  left: 0;
  border-radius: 0 3px 3px 0;
  &::after {
    transform: rotate(-135deg);
    left: 0;
    right: 0;
  }
}

.carousel-thumbnail {
  display: none;
}
@mixin mq  {
  .carousel-thumbnail {
    display: block;
    width: 581px;
  }
}
