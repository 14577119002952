@import "variables";

a.btn,
button.btn {
  cursor: pointer;
}
.btn {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
}

div.btn:not(:disabled):not(.disabled) {
  cursor: default;
}

@each $type, $color in (primary: #0f667f, secondary: #eb6536, cancel: #ebebeb, mute: #666) {
  .btn-#{$type} {
    background-color: $color;
    border-color: $color;
    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      color: $color;
      border-color: $color;
    }
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus {
      background-color: $color;
      color: #fff;
      border-color: $color;
    }
  }
  div.btn-#{$type} {
    &:hover,
    &:focus {
      background-color: $color;
      color: #fff;
    }
  }
}
.btn-cancel {
  &:hover {
    background-color: $color-danger;
    color: #fff;
  }
}
.btn-mute {
  color: #fff;
}

.btn-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 16px;
}

.btn-orange {
  background: $base-orange;
  color: #fff !important;
}

.btn-outline {
  background-color: #fff;
  &.btn-primary {
    color: $primary-color;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: $primary-color;
    }
  }
  &.btn-secondary {
    color: $secondary-color;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }
  &.btn-mute {
    color: #666;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: #666;
      border-color: #666;
    }
  }
}
.btn-round {
  border-radius: 40px;
}

/* 欲しい！ボタン */
.btn-want {
  border-radius: 40px;
  display: block;
  padding: 15px 0;
  &::before {
    display: inline-block;
    @mixin iconFont {
      content: "\f004";
    }
    margin-right: 6px;
    color: #dc3545;
  }
  &:hover {
    color: #fff;
  }
}
.btn-tel {
  display: block;
  padding: 15px 0;
  &::before {
    display: inline-block;
    @mixin iconFont {
      content: "\f086";
    }
    margin-right: 6px;
  }
}
.btn-sms {
  display: block;
  padding: 15px 0;
  &::before {
    display: inline-block;
    @mixin iconFont {
      content: "\f095";
    }
    margin-right: 6px;
  }
}
.salesStart {
  &.btn-want {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: #fff;
    &::before {
      color: #fff;
    }
  }
}
.salesEnd {
  &.btn-want {
    background-color: #c6c6c6;
  }
}
/* まかせて、編集 */
.btn-footer-edit {
  display: block;
  width: 100%;
  text-align: center;
  border-top: 1px solid #dee2e6;
  margin-top: 20px;
  padding: 20px 0;
  color: $font-black;
  font-weight: bold;
  font-size: 14px;
  &:hover {
    color: #fff;
    background-color: $secondary-color;
    text-decoration: none;
  }
}

.btn-sp-footerfix {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  transition: all 0.5s ease;
  &.btn {
    border-radius: 0;
  }
}

/* 受付・販売終了表記 */

.salesEnd,
.salesStart {
  background-color: #333;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}

/* LINEボタン */

.btn.btn-line,
.btn.btn-line--disconnect,
.btn.btn-line:disabled {
  width: 100%;
  display: block;
  color: #fff;
  border-radius: 4px;
  height: auto;
  line-height: 1;
  padding: 15px 20px;
  background-image: url(../images/logo_line.svg);
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 30px auto;
  box-sizing: border-box;
  text-align: center;
}
.btn.btn-line {
  background-color: #00c300;
  margin-bottom: 8px;
  &:hover {
    background-color: #00e000;
  }
}
.btn.btn-line--disconnect,
.btn.btn-line:disabled {
  background-color: #c6c6c6;
  opacity: 1;
}

/* リクエストボタン */
.btn-request {
  position: fixed;
  right: 3%;
  bottom: 3%;
  z-index: 5;
  width: 100%;
  .btn {
    width: 110px;
    height: 110px;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    transition: all 300ms 0s ease;
    right: -2px;
    bottom: -2px;
    line-height: 1.2;
    &:hover {
      width: 120px;
      height: 120px;
      font-size: 13px;
      i.fas {
        font-size: 32px;
        margin: 5px 0 3px;
      }
    }
  }
  i.fas {
    display: block;
    font-size: 30px;
    margin: 5px 0 3px;
  }
  .btn-outline {
    border-width: 4px;
  }
  .btn-outline.btn-secondary {
    &:active {
      border-color: $secondary-color;
    }
  }
  &.is-scroll {
    .btn {
      opacity: 0;
      bottom: -30px;
      right: -100px;
      bottom: -100px;
      transform: rotate(90deg);
    }
  }
}

/* ラウンドボタン */

.btn-round--primary,
.btn-round--secondary {
  text-align: center;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  padding: 10px 15px;
  border-radius: 20px;
}

.btn-round--secondary {
  color: $secondary-color;
  border-color: $secondary-color;
}

/* ファイルアップロードボタン */

.btn-filebtn {
  input[type="file"] {
    display: none;
  }
  background-color: #fff;
  display: block;
  width: 200px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid $base-green;
  color: $base-green;
  text-align: center;
  font-size: 12px;
  &::before {
    content: "ファイルをアップロードする";
  }
  &:hover {
    background-color: $base-green;
    color: #fff;
    cursor: pointer;
  }
}

/* mq */

@mixin mq {
  .btn-sp-footerfix {
    position: static;
    &.btn {
      border-radius: 4px;
    }
    &.btn-want {
      border-radius: 40px;
    }
  }
  .btn-request {
    .btn {
      width: 140px;
      height: 140px;
      bottom: 0;
      right: 10px;
      line-height: 1.2;
      font-size: 14px;
      &:hover {
        width: 150px;
        height: 150px;
        font-size: 15px;
        right: 10px;
        i.fas {
          font-size: 36px;
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
      i.fas {
        font-size: 34px;
        margin: 18px 0 8px;
      }
    }
    .btn-outline {
      border-width: 5px;
    }
    &.is-scroll {
      .btn {
        opacity: 0;
        bottom: -20px;
        right: 10px;
        transform: rotate(0deg);
      }
    }
  }
}

@mixin mq {
  .btn.btn-line,
  .btn.btn-line--disconnect,
  .btn.btn-line:disabled {
    width: 100%;
    background-image: none;
    padding: 6px 20px;
    &::before {
      content: "";
      width: 30px;
      height: 30px;
      display: inline-block;
      background-image: url(../images/logo_line.svg);
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 30px auto;
      vertical-align: middle;
      margin: 0 20px 0 -45px;
    }
  }
  width: 415px;
}
