@import "../../frontend/stylesheets/styles/_variables";

.carousel-image {
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      width: 100%;
      height: 444px;
    }

    .swiper-slide-image {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      width: 100%;
      height: 0;
      padding-top: 73%;
      background-color: #000;
    }
  }

  .carousel-thumbnail {
    ul {
      display: flex;
      justify-content: center;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin: 20px 10px 20px;
        border: 1px solid $base-green;
        width: 137px;
        height: 103px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.active {
          opacity: 0.8;
          border: 3px solid $base-green;
          filter: alpha(opacity=80);
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 415px) {
    .swiper-slide {

      img,
      video {
        width: 100%;
        height: 280px;
      }
    }

    .carousel-thumbnail {
      display: none;
    }
  }
}
