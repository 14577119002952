@import "variables";
@import "./_mixins";

.link-block {
  display: block;
}

a,
.link-primary {
  color: $primary-color;
  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}

.link-blue {
  color: #2996cc;
  &:hover {
    color: #2996cc;
  }
}
.link-arrow {
  &::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #2996cc;
    border-right: 1px solid #2996cc;
    transform: rotate(45deg);
    vertical-align: middle;
  }
}

.link-arrow-right {
  @extend .link-arrow;
  position: relative;
  display: block;
  &::after {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.link-arrow-left {
  @extend .link-arrow;
  position: relative;
  display: block;
  &::after {
    transform: rotate(-135deg);
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.link-arrow-down {
  @extend .link-arrow;
  position: relative;
  display: block;
  &::after {
    transform: rotate(135deg);
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.back-link {
  display: block;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid $font-black;
    border-right: 1px solid $font-black;
    -webkit-transform: rotate(45deg);
    transform: rotate(-135deg);
    vertical-align: middle;
    margin-right: 8px;
  }
  &:hover {
    &::before {
      border-color: $base-green;
    }
  }
}

.nav-pills {
  .nav-item {
    .nav-link {
      &.active,
      &:hover {
        background-color: $primary-color;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
}
