@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_mixins";

.footer {
  background: $base-white;
  padding: 42px 0 10px;
  border-top: 1px solid $border-gray-dark;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  transition: all 0.5s ease;
}

.footer__logo {
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 80px;
  }
}

.footer__sub {
  background: $base-white;
}

.footer__link {
  text-align: center;
  font-size: 12px;
}

.footer__link__list {
  margin-bottom: 0;

  a {
    color: $font-black;
  }

  li {
    margin-bottom: 3px;
  }
}

.footer__sub-copyright {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  color: $darkgrey;
}

@mixin mq {
  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 145px;
    width: 581px;
  }

  .footer__link {
    text-align-last: left;
  }

  .footer__sub-copyright {
    br {
      display: none;
    }
  }
}

@mixin mq {
  .footer__sub-copyright {
    margin-top: 0;
  }
}
