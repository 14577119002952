@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_mixins";

.selling-information-form {

  .form-control[type="text"],
  .form-control[type="email"],
  .form-control[type="password"] {
    padding: 7px 10px;
  }

  .select2-container--bootstrap .select2-selection--single {
    height: 40px;
  }
}

.selling-information-form__section {
  background-color: #fff;

  .row {
    align-items: center;
  }
}

@mixin mq {
  .selling-information-form__section {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
