.sg-nav {
  .nav-tabs {
    .nav-link {
      &.active,
      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}

.sg-wrapper {
  border: solid #ccc;
  border-width: 0 3px 3px;
  padding: 20px;
  box-sizing: border-box;
  .c-radio-checkstyle {
    display: inline-block;
  }
}

.sg-heading {
  background-color: #333;
  color: #fff;
  padding: 20px;
  margin-bottom: 0;
}

.sg-codeblock {
  background-color: #f5f5f5;
  padding: 10px;
  display: block;
  border-radius: 4px;
  margin-top: 6px;
}
pre.sg-codeblock {
  color: #e83e8c;
}

.c-custom-radio--checked {
  display: inline-block;
}
