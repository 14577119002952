@import "../../frontend/stylesheets/styles/_variables";

.item-modal-check {
  position: relative;
  width: 120px;
  height: 120px;
  background: #3cb371;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  margin: 20px auto;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -60px;
    width: 120px;
    height: 120px;
    background: #fff;
    transform-origin: right 60px;
    z-index: 2;
    animation: rotate-circle-left 0.6s linear forwards;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 60px;
    width: 120px;
    height: 120px;
    background: #fff;
    transform-origin: left 60px;
    z-index: 3;
    animation: rotate-circle-right 0.6s linear forwards;
  }
}

.item-modal-check-inner {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 110px;
  height: 110px;
  background: #fff;
  border-radius: 50%;
  z-index: 4;
}

.item-modal-check span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 85px;
  height: 85px;
  transform-origin: center;
  margin-right: 10px;

  &:before {
    content: "";
    width: 0;
    height: 7px;
    border-radius: 7px;
    background: #3cb371;
    position: absolute;
    transform: rotate(45deg);
    top: 50px;
    left: 15px;
    transform-origin: 0% 0%;
    animation: check-short 0.2s linear forwards 0.5s;
  }

  &:after {
    content: "";
    width: 0;
    height: 7px;
    border-radius: 7px;
    background: #3cb371;
    position: absolute;
    transform: rotate(315deg);
    top: 77px;
    left: 35px;
    transform-origin: 0% 0%;
    animation: check-long 0.2s linear forwards 0.7s;
  }
}

.item-modal-message {
  animation: done-message 0.2s linear forwards 0.9s;
  opacity: 0;
}

.item-modal-buttons {
  a.btn {
    width: 48%;
    font-size: 13px;
  }

  a.btn-primary {
    color: #fff;

    &:hover {
      color: $primary-color;
    }
  }
}

@keyframes rotate-circle-right {
  0% {
    transform: rotate(0deg);
    background: #fff;
  }

  50% {
    transform: rotate(180deg);
    background: #fff;
  }

  50.01% {
    transform: rotate(360deg);
    background: #3cb371;
  }

  100% {
    transform: rotate(360deg);
    background: #3cb371;
  }
}

@keyframes rotate-circle-left {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes check-short {
  0% {
    width: 0;
  }

  50% {
    width: 20px;
  }

  100% {
    width: 40px;
  }
}

@keyframes check-long {
  0% {
    width: 0;
  }

  50% {
    width: 30px;
  }

  100% {
    width: 60px;
  }
}

@keyframes done-message {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
