@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_mixins";

.selling-information-list {
  .c-item-card {
    margin-bottom: 30px;
  }

  .c-item-card__status {
    &::before {
      top: -8px;
      z-index: 10000;
    }

    span {
      top: 16px;
      z-index: 10000;
    }
  }

  .load-more-selling-informations {
    margin: 20px 0;
    text-align: center;
  }
}

.container+.sticky {
  padding-top: 102px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10001;
}

.selling-information-tab {
  background: #fff;
  box-shadow: -1px 2px 2px #cfcfcf;
  border-bottom: none;

  li {
    text-align: center;
    width: 50%;

    a {
      display: inline-block;
      padding: 10px;
      width: 100%;
      color: #1f1e1e;
    }
  }

  li.active {
    border-bottom: 4px solid #0f667f;

    a {
      font-weight: bold;
      color: #0f667f;
    }
  }
}
