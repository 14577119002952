.form-control[type="text"],
.form-control[type="email"],
.form-control[type="password"] {
  padding: 10px;
}
input::placeholder {
  color: #c6c6c6 !important;
}

.label-required {
  background-color: $secondary-color;
  padding: 4px 10px 5px;
  font-size: 12px;
  border-radius: 15px;
  margin-left: 5px;
  color: #fff;
}

.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: red !important;
}
