.sign-in {
  background-color: #fff;
}

.sign-in__inner {
  padding: 0 25px;
}

.sign-in__form {
  .btn,
  label {
    font-size: 13px;
    font-weight: bold;
  }
}

@mixin mq {
  .sign-in {
    background-color: transparent;
  }
  .sign-in__inner {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 50px 60px 35px;
    border-radius: 3px;
  }
}
