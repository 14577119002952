.load-more {
  .mutable {
    a {
      width: 200px;
    }
  }

  .loading {
    display: none;
    width: 44px;
    height: 44px;
    margin: 0 auto;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: load-more-loading-spin 2s linear infinite;
  }
}

@keyframes load-more-loading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
