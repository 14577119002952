@import "../../stylesheets/styles/_variables";
@import "../../stylesheets/styles/_mixins";

/* MV */
.home-mv {
  background-color: #fff;

  .heading-02--large {
    font-size: 18px;
    background: url(../images/logo_lineat-white.svg) no-repeat 50% 20px #00c300;
    background-size: 100px auto;
    color: #fff;
    padding: 60px 10px 20px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 16px 10px 0 10px;
      border-color: #00c300 transparent transparent transparent;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      margin: auto;
    }
  }
}

.home-mv__line,
.home-mv__image {
  box-sizing: border-box;
}

.home-mv__line {
  padding: 0 0 30px;
}

.home-mv__image {
  position: relative;
  padding-top: 50%;
  background: url(../images/img_home-mv-left.jpg) no-repeat 50% 50%;
  background-position: 50% 50%;
  background-size: cover;
}

.home-mv__sp-addbtn {
  a {
    padding: 15px 30px;
    border-radius: 30px;
    background-color: #00b900;
  }
}

.home-mv__pc {
  display: none;
}

/* 検索 */
.search {

  select,
  input {
    display: block;
    width: 100%;
    padding: 10px 0;
  }
}

.search__form {
  width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  margin: auto;
}

.search-bg {
  height: 220px;
  background: url(../images/img_home-main-sp.jpg) no-repeat 50% 50%;
  background-size: cover;
}

.search__item--left {
  border-radius: 4px 4px 0 0;
}

.search__item--right {
  border-radius: 0 0 4px 4px;
  margin-bottom: 20px;
}

@mixin mq {
  .search__form {
    width: 60%;
  }
}

@mixin mq {
  .home-mv {
    position: relative;
    height: 460px;
    background: url(../images/img_home-mv.jpg) no-repeat 50% 50%;
    background-size: cover;
  }

  .home-mv__pc {
    display: block;

    img {
      width: 140px;
    }

    .heading-02--large {
      font-size: 20px;
      color: #00c300;
      padding: 0;
      background: url(../images/logo_lineat-green.svg) no-repeat 50% 0 transparent;
      background-size: 140px auto;
      padding-top: 50px;
      position: static;

      &::after {
        content: none;
      }
    }

    .home-mv__line {
      background-color: rgba(255, 255, 255, 0.8);
      width: 290px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      padding: 50px 20px 20px;
      opacity: 0;
      animation-name: slidedown;
      animation-duration: 2s;
      animation-fill-mode: forwards;
    }
  }

  .home-mv__sp {
    display: none;
  }

  .search-bg {
    height: 380px;
    background: url(../images/img_home-main.jpg) no-repeat 50% 50%;
    background-size: cover;
  }

  .search__form {
    width: 100%;
    bottom: 20px;
  }

  .search__item--left {
    border-radius: 4px 0 0 4px;
  }

  .search__item--right {
    border-radius: 0 4px 4px 0;
    margin-bottom: 0;
  }

  @keyframes slidedown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin mq {
  .home-mv__image {
    padding-top: 38%;
  }

  .home-mv__pc {
    .home-mv__line {
      right: 2%;
      width: 320px;
    }
  }
}

/* 魚種から選ぶ */

.home-type {
  padding-bottom: 30px;
}

.home-type__items {
  list-style-type: none;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home-type__item {
  flex-basis: 30%;
  margin: 0 1% 1%;
}

.home-type__item a {
  display: block;
  text-decoration: none;

  &:hover img {
    opacity: 0.8;
  }
}

.home-type__item img {
  display: block;
  margin: 0 auto 10px;
}

.home-type__item-name {
  font-size: 13px;
}

.home-content {
  @media screen and (min-width: 581px) {
    padding-bottom: 30px;
  }
}

.home-type-link {
  display: block;
  width: 300px;
  font-size: 14px;
  margin: 30px auto;
}

/* LINE@ */

.home-line-at {
  background-color: #00b900;
  position: relative;
  border-top: 1px solid $border-gray-dark;
  padding-top: 30px;
  padding-bottom: 30px;

  .show-sp-only {
    display: block;
  }

  .show-pc-only {
    display: none;
  }
}

.home-line-at__addbtn {
  text-align: center;

  a {
    padding: 15px 30px;
    border: 1px solid #fff;
    border-radius: 30px;
  }
}

.home-line-at__title {
  margin-bottom: 15px;

  img {
    max-width: 120px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.home-line-at__content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.home-line-at__message {
  color: #fff;
  width: 90%;
  margin: 20px auto;
  line-height: 1.8;
  text-align: center;
}

/* mq */

@mixin mq {
  .home-line-at {
    .show-pc-only {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .show-sp-only {
      display: none;
    }

    .home-line-at__title {
      flex-basis: 30%;
      padding: 0 30px;
      box-sizing: border-box;
      text-align: center;
      vertical-align: bottom;

      img {
        max-width: 140px;
        min-width: 120px;
      }
    }

    .home-line-at__content {
      flex-basis: 70%;
      position: relative;
      box-sizing: border-box;
      padding: 20px 20px 18px;
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
    }

    .home-line-at-qrcode {
      margin-right: 10px;
      flex-basis: 150px;

      img {
        height: 90px;
      }
    }

    .home-line-at__message {
      line-height: 1.6;
      color: $font-black;
      width: 100%;
      text-align-last: left;
    }

    .home-line-at__message-id {
      background-color: #e5f8e5;
      padding: 5px 15px 5px 10px;
      border-radius: 15px;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.6;

      &::before {
        @include iconFont("\f002"
        );
        color: #ccc;
        margin-right: 8px;
      }
    }

    .home-line-at__addbtn {
      text-align: center;

      a {
        padding: 15px 30px;
        border: 1px solid #fff;
        border-radius: 30px;
      }
    }
  }
}

@mixin mq {
  .home-type__items {
    flex-wrap: nowrap;
  }

  .home-type__item {
    width: 23%;
  }
}

/* もっと見るボタン */
@media screen and (max-width: 581px) {
  .home-link-more {
    // @extend .btn-round;
    border: 1px solid $secondary-color;
    color: $secondary-color;
    display: block;
    margin: 10px auto;
    padding: 8px 20px;
    width: 20em;
    box-sizing: border-box;
    font-size: 13px;

    &::after {
      border-color: $secondary-color;
      margin-left: 10px;
    }

    &:hover {
      background-color: $secondary-color;
      color: #fff;
      text-decoration: none;

      &::after {
        border-color: #fff;
      }
    }
  }
}
