@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_font";
@import "../../frontend/stylesheets/styles/_mixins";

.order-list {
  .order-list-load-more {
    margin: 20px 0;
    text-align: center;
  }
}
