@import "../../frontend/stylesheets/styles/_variables";

.home-products {
  margin-top: 40px;

  .swiper-container {
    padding-bottom: 50px;
    clear: both;
    height: auto;
  }
}
