@charset "UTF-8";
/* stylelint-disable no-descending-specificity  */
@import "variables";

html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体",
    "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-weight: 500;
  background: #fafafa;
  color: $font-black;
  transition: all 0.5s ease;
  &.slide-left {
    margin-left: 200px;
    width: 100%;
    overflow: hidden;
    .header,
    .footer {
      left: 200px;
    }
    .left {
      .button {
        left: 200px;
      }
    }
    .right {
      .button {
        right: -200px;
      }
    }
    .btn-sp-footerfix {
      left: 200px;
    }
    .login {
      right: -200px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $font-black;
}

ul {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  &.unstyle {
    list-style-type: none;
  }
}

.text {
  font-family: YuGothic, sans-serif;
  color: $font-black;
  font-size: 13px;
}
img {
  max-width: 100%;
}

video {
  vertical-align: middle;
}

a,
button {
  cursor: pointer;
}

.bootstrap-select .dropdown-toggle:focus,
button,
input,
select,
textarea {
  outline: none;
  outline-offset: initial;
  background-color: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
}

.wrapper {
  padding-bottom: 60px;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none;
}

@mixin mq  {
  .container {
    max-width: 98% !important;
    width: 576px;
  }
}

/* Form */
.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback,
  .help-block,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label {
    color: red;
    font-size: 13px;
    font-weight: bold;
  }

  .form-control {
    border-color: red;

    &:focus {
      border-color: red;
    }
  }

  .input-group-addon {
    color: red;
    border-color: red;
    background-color: #f2dede;
  }
}

.parsley-required {
  color: red;
  font-size: 13px;
  font-weight: bold;
}

@mixin mq {
  body {
    &.slide-left {
      .header,
      .footer {
        left: 200px;
      }
      .left {
        .button {
          right: 0;
          left: auto;
        }
      }
      .right {
        .button {
          right: 60px;
        }
      }
      .login {
        right: 55px;
      }
    }
    width: 415px;
  }
}
@mixin mq {
  body {
    margin-bottom: 145px;
    width: 581px;
  }
}
@mixin mq {
  .wrapper {
    padding-bottom: 100px;
  }
}
