@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_font";
@import "../../frontend/stylesheets/styles/_mixins";

.picture-uploader {
  input[type="file"] {
    display: none;
  }

  position: relative;

  >.filled {
    position: absolute;
    font-size: 12px;
    color: red;
    bottom: 0;
    left: 0;
  }
}

.picture-uploader__preview {
  label {
    display: block;
    width: 100%;
    background-color: #ccc;
    background-position: center center;
    background-repeat: no-repeat;
    // background-image: url(../../../node_modules/@fortawesome/fontawesome-free/svgs/solid/camera.svg);
    background-size: 1em;
    line-height: 0;

    span {
      display: inline-block;
      width: 100%;
      padding-top: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.picture-uploader__actions {}

.picture-uploader__action {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;

  label {
    margin-bottom: 0;
  }

  &.btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &.select {
    &::before {
      @mixin iconFont {
        content: "\f07c";
      }

      margin-right: 3px;
    }
  }

  &.remove {
    &::before {
      @mixin iconFont {
        content: "\f2ed";
      }

      margin-right: 3px;
    }

    button {
      color: inherit;
      padding: 0;
    }
  }
}
