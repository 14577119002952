@import "../../stylesheets/styles/_variables";
@import "../../stylesheets/styles/_font";
@import "../../stylesheets/styles/_mixins";
@import "home/index";

.side-nav__items {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
}

.side-nav__item {
  border-bottom: 1px solid $border-gray;
  font-size: 14px;

  &:last-child {
    border-bottom: none;
  }

  a {
    display: block;
    background-color: #fff;
    padding: 20px 10px;
    box-sizing: border-box;

    &:hover {
      background-color: #edf2f3;
      text-decoration: none;
    }
  }

  &.active {
    a {
      background-color: #edf2f3;
      color: $font-black;
    }
  }
}

.side-nav__item-icon {
  width: 36px;
  height: auto;
  vertical-align: middle;
  margin: 0 10px;
}
