@import "variables";

/* Color */

.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

/* Size */

.text-xxlarge {
  font-size: 160%;
}
.text-xlarge {
  font-size: 140%;
}
.text-large {
  font-size: 120%;
}
.text-small {
  font-size: 90%;
}
.text-xsmall {
  font-size: 80%;
}
