@import "variables";
@import "./_mixins";
@import "./_font";
/* 商品一覧用カード */

.c-item-card {
  position: relative;
  .card-body {
    padding: 10px;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.c-item-card__picture {
  span {
    display: inline-block;
    background-color: #000;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 100%;
    background-size: contain;
    border-radius: 3px 3px 0 0;
  }
}

.c-item-card__status {
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 4px;
    border-style: solid;
    border-width: 50px 50px 0 50px;
    border-color: $base-green transparent transparent transparent;
    position: absolute;
    top: -8px;
    left: -33px;
    transform: rotate(135deg);
  }
  span {
    text-align: center;
    color: $base-white;
    font-size: 11px;
    transform: rotate(-45deg);
    position: absolute;
    top: 15px;
    left: 0;
    font-weight: bold;
  }
}

.c-item-card__name {
  margin: 10px 10px 5px;
}

.c-item-card__price-wrap {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: Avenir, sans-serif;
}

.c-item-card__price-title {
  font-size: 11px;
  font-weight: normal;
  text-align: center;
  padding: 2px 5px;
  background: $base-orange;
  color: #fff;
  display: block;
  width: 8em;
  text-align: center;
}

.c-item-card__price {
  color: $base-orange;
}

.c-item-card__price small {
  color: $font-black;
  font-size: 12px;
  font-weight: bold;
}

.c-item-card__supplementary {
  font-size: 12px;
  color: #666;
  display: flex;
  & > :last-child {
    margin-left: auto;
  }
}

.c-item-card__price-unit {
  padding: 3px 10px 3px 20px;
  margin: -3px 0 0 8px;
  display: inline-block;
  font-size: 11px;
  background-color: $base-orange;
  color: #fff;
  font-weight: normal;
  vertical-align: middle;
  position: relative;
  letter-spacing: 0.08em;
  &::before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 1px;
    background-color: #666;
    position: absolute;
    left: -6px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.c-item-card-state {
  font-size: 12px;
  color: #666;
  padding-bottom: 15px;
}

.c-item-card__organization {
  margin-left: 10px;
  font-size: 12px;
  color: #666;
}
.c-item-card__organization-icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

/* mq */

@mixin mq {
  .c-item-card__supplementary {
    display: block;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    width: 576px;
  }
  .c-item-card__organization {
    display: block;
    margin: 0;
    width: 100%;
  }
  .c-item-card__area {
    float: left;
  }
  .c-item-card__time {
    float: right;
  }
}
@mixin mq {
  .c-item-card {
    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

/* 商品詳細時間 */

.c-selling-time__date,
.c-selling-time__line {
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.c-selling-time__date {
  background-color: $line-gray;
  padding-top: 20px;
  padding-bottom: 0;
}

.c-selling-time__line {
  background-color: $primary-color;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 0;
}

.c-selling-time-label {
  display: block;
  margin-bottom: 10px;
}

.c-countdown-timer-time {
  font-size: 180%;
  &.second {
    font-size: 120%;
  }
}

/* tag */

.c-tag {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c-tag-item {
  color: #fff;
  font-size: $font-size-s;
  display: inline-block;
  margin-right: 5px;
  a {
    background-color: $primary-color;
    color: #fff;
    padding: 5px 15px;
    border-radius: 15px;
  }
}

/* form parts */

.c-select-wrap__inner {
  .form-control {
    padding: 3px 40px;
    display: inline;
    width: auto;
  }
}

.c-custom-radio,
.c-custom-radio--checked {
  display: inline-block;
  border: 1px solid $form-border;
  width: auto;
  padding: 10px 20px 10px 40px;
  border-radius: 4px;
  position: relative;
  input[type="radio"] {
    &:checked {
      + label {
        &::after {
          background-color: $secondary-color;
        }
      }
    }
  }
  label {
    margin: 0;
    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      border: 1px solid $form-border;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto;
    }
    &::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      background-color: $form-border;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 14px;
      margin: auto;
    }
  }
}

.c-custom-radio--checked {
  label {
    &::after {
      background-color: $secondary-color;
    }
  }
}

.c-checkboxstyle {
  input[type="checkbox"] {
    -webkit-appearance: checkbox !important;
  }
}

.auction-information-checkbox {
  margin-right: 15px;
}

.btn-auction-information {
  margin-top: 0 !important;
}

.auction-information-button-group {
  text-align: right;
}

.c-unavailable {
  text-decoration: line-through;
}

.c-radio-checkstyle {
  input[type="radio"] {
    border: 0;
    width: 0;
    height: 0;
    padding: 0;
    background-color: transparent;
    + label {
      padding: 10px 15px 10px 50px;
      border: 1px solid $border-gray;
      background-color: #fff;
      border-radius: 6px;
      position: relative;
      &::before {
        content: "";
        display: block;
        border: 1px solid $border-gray;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &::after {
        content: "";
        display: block;
        width: 5px;
        height: 8px;
        border: solid $border-gray;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        left: 23px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &:checked {
      + label {
        background-color: $base-orange;
        color: #fff;
        &::before {
          background-color: #fff;
          border-color: #fff;
        }
        &::after {
          border-color: $base-orange;
        }
      }
    }
  }
}

/* Tab */

.c-tabs {
  border-bottom: 3px solid $primary-color;
}

.c-tabs__item {
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  margin-left: 2px;
  margin-right: 2px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0.5px;
  }
  a {
    display: block;
    border: none !important;
    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }
  &.active {
    background-color: $primary-color;
    a {
      color: #fff;
    }
  }
  &.nav-item {
    margin-bottom: 0;
  }
}

/* mypage */

.c-order-data--blank {
  text-align: center;
  background: url(../images/bg_logo-gray.svg) no-repeat 50% 25% #edf2f3;
  background-size: 150px auto;
  color: #666;
  padding: 200px 0 60px;
  margin-top: -15px;
}

.c-user-info__icon {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.c-user-info-name {
  span {
    display: block;
  }
}

.c-user-info__menu-item {
  a {
    display: block;
    color: $font-black;
    &:hover {
      text-decoration: none;
    }
  }
}

/* user message */
.c-user-message {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px 0 10px;
}
.c-user-message__icon {
  margin-right: 0;
  img {
    min-width: 80px;
    height: 80px;
    mask-image: url(../images/profile_mask.svg);
    margin: 0 auto 20px;
    display: block;
  }
}
.c-user-message__txt {
  width: 100%;
  background-color: #dfecef;
  border-radius: 6px;
  padding: 15px;
  position: relative;
  display: block;
  &::after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 16px 6px;
    border-color: transparent transparent #dfecef transparent;
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
  }
}

@mixin mq {
  .c-user-message {
    flex-direction: row;
    text-align: left;
  }
  .c-user-message__icon {
    margin-right: 25px;
  }
  .c-user-message__txt {
    width: 80%;
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 15px 6px 0;
      border-color: transparent #dfecef transparent transparent;
      position: absolute;
      left: -15px;
      top: 25px;
      margin: 0;
    }
  }
}

/* breadcrumb */
.c-breadcrumb {
  list-style-type: none;
  margin: 0 0 0.5rem;
  padding: 12px 20px;
  background: #f5f5f5;
}
.c-breadcrumb-item {
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
  color: #666;
}
.c-breadcrumb-item a {
  position: relative;
  padding-right: 12px;
  &:hover {
    text-decoration: none;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    height: 5px;
    margin: auto;
    border-top: 1px solid #666;
    border-right: 1px solid #666;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@mixin mq {
  .c-breadcrumb-item {
    margin-right: 10px;
  }
  .c-breadcrumb-item a {
    padding-right: 20px;
  }
}
