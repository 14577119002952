.alert {
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0 40px 0 0;
  font-size: 13px;
  display: flex;
  align-items: stretch;
  position: absolute;
  width: 100%;
  left: 0;
  top: 68px;
  z-index: 100;
  &::before {
    display: block;
    margin-right: 10px;
    padding: 25px 10px;
    font-size: 16px;
  }
  .alert-message {
    text-align-last: left;
    padding: 10px 5px 10px 0;
    align-self: center;
  }
  .alert-dismissable,
  .alert-dismissible {
    padding-right: 35px;
  }
  &.alert-danger {
    background-color: #c74444;
    background-image: none;
    color: #fff;
    &::before {
      @mixin iconFont {
        content: "\f071";
      }
      background-color: #a90d0e;
    }
  }
  &.alert-info {
    background-color: #c3d1d9;
    color: $font-black;
    background-image: none;
    &::before {
      @mixin iconFont {
        content: "\f129";
      }
      background-color: #9eb0ba;
      padding-left: 20px;
      padding-right: 20px;
      color: #fff;
    }
  }
  &.alert-success {
    background-color: #3eb56e;
    color: #fff;
    background-image: none;
    &::before {
      @mixin iconFont {
        content: "\f00c";
      }
      background-color: #319f5d;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &.alert-warning {
    background-color: #e6b242;
    color: #fff;
    background-image: none;
    &::before {
      @mixin iconFont {
        content: "\f071";
      }
      background-color: #e2a41f;
    }
  }
  .close {
    opacity: 1;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    span {
      color: #fff;
    }
  }
  @media screen and (min-width: 415px) {
    top: 74px;
  }
}
