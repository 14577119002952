@import "../../frontend/stylesheets/styles/_variables";

/* stylelint-disable */
.header {
  background: $base-white;
  width: 100%;
  padding: 20px 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 9;
  transition: all 0.5s ease;
  position: relative;

  .btn {
    margin: 0 8px;
  }
}

.header__logo {
  img {
    width: 60px;
    height: auto;
    margin-left: 20px;
  }
}

.header__menu {
  .header__menu-icon {
    position: absolute;
    top: 12px;
    right: 10px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    display: block;
  }

  .header__menu-login {
    position: absolute;
    top: 28px;
    right: 10px;
    display: block;
    font-size: 14px;
  }
}

.header__menu-icon {
  right: 10px;

  &.user-icon {
    min-width: 50px;
    height: 50px;
  }
}

.product-list-btn {
  background: $base-white;

  .btn {
    width: 170px;
  }

  i.fas {
    vertical-align: middle;
    font-size: 18px;
    line-height: 1;
  }

  @media screen and (max-width: 812px) {
    margin-right: 60px;
  }

  @media screen and (max-width: 580px) {
    display: none;
  }
}

.login {
  position: fixed;
  top: 3px;
  right: 55px;
  z-index: 10;
  transition: all 0.5s ease;

  .show-sp-only & {
    right: 32px;
  }

  .hide-sp-only & {
    right: 68px;
  }

  a {
    line-height: 40px;
    margin: 14px 0;
    display: block;
    font-size: 12px;
    color: $primary-color;
    text-decoration: none;
  }
}

.header-modal {
  .modal-header {
    border-bottom: 1px solid $line-gray;
  }

  h4 {
    font-size: 16px;
    margin: 10px 0 0;
  }

  .item-type-choose {
    padding: 0;
    display: flex;
    justify-content: center;

    .item-type {
      position: relative;
      display: block;
      background-color: #f5f5f5;
      border-radius: 4px;
      padding: 14px 10px 14px 40px;
      width: 12em;
      text-align: left;

      &:first-child {
        margin-right: 20px;
      }

      &.active,
      &:hover {
        background-color: $primary-color;
        color: #fff;
        text-decoration: none;

        &::after {
          border-color: $primary-color;
        }
      }

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        display: inline-block;
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 50%;
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        content: "";
        width: 5px;
        height: 10px;
        border: solid 2px #ccc;
        border-left: 0;
        border-top: 0;
        transform: translateY(-50%);
        transform: rotate(45deg);
        top: 0;
        bottom: 0;
        left: 18px;
        margin: auto;
        z-index: 4;
      }
    }
  }
}

@media screen and (max-width: 415px) {
  .header--sp {
    background-color: $base-white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }

  .header--sp__flex-container {
    height: 44px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .header--sp__center {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .header__logo img {
    width: 45px;
    padding-top: 4px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .login {
    right: 5px;
  }

  .header.open {
    .login {
      a {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 2500px) and (max-width: 415px) {
  .header--sp {
    z-index: 100;
  }

  .header--sp__logo img {
    width: 52px;
    padding-top: 4px;
  }
}
