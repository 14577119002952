@import "../../../stylesheets/styles/_variables";
@import "../../../stylesheets/styles/_font";
@import "../../../stylesheets/styles/_mixins";

.mypage-menu {
  .mypage-menu-item {
    font-size: 14px;
    padding: 0;

    a {
      text-decoration: none;
      padding: 1rem 1.25rem;
      color: $font-black;

      &.link-arrow-right {
        &::after {
          border-color: $primary-color;
        }
      }

      &:hover {
        background-color: #edf4f4;
      }
    }
  }
}

.mypage-requests__detail {
  font-size: 14px;
}

.mypage-requests__thumb {
  width: 120px;
  height: auto;
}

.mypage-requests__meta {
  text-align: center;
  margin-bottom: 5px;
}

.mypage-requests__meta-status {
  font-size: 13px;
  text-align: center;
  margin: 0 0 5px 0;
  padding: 6px 10px 4px;
  color: #fff;
  display: block;

  &.status-active {
    background-color: #5cb85c;
  }

  &.status-end {
    background-color: #d9534f;
  }
}

.mypage-requests__overview-item {
  margin-bottom: 10px;
}

.mypage-requests__btn {
  margin: 10px 0;
  text-align: center;

  .btn {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 60%;
  }
}

.btn-mypageitem-more {
  display: block;
  width: 20em;
  margin: 40px auto 10px;
}

@mixin mq {
  .mypage-requests__overview-item {
    margin-bottom: 5px;
  }

  .mypage-requests__meta {
    text-align: left;
  }

  .mypage-requests__meta-status {
    display: inline-block;
    margin: 0 10px 0 0;
  }
}

@mixin mq {
  .mypage-requests__btn {
    .btn {
      width: 100%;
    }
  }
}

@mixin mq {
  .mypage-requests__detail {
    display: flex;
    align-items: center;
  }
}
