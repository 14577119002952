.countdown-timer {
  font-family: Avenir, sans-serif;

  strong {
    font-size: 29px;
  }

  .second {
    font-size: 18px;
  }

  span {
    font-size: 12px;
    padding: 0 5px 0 4px;
  }
}
