.hello {
  position: relative;
  grid-column: 3/4;
  margin-bottom: 2em;
  padding: 1em;
  background-color: #f7f5f2;
  display: flex;
  align-items: center;
  border: 0.25em solid #fff;
  box-shadow: 1px 10px 20px #d9c6a4;

  &__input {
    display: block;
    margin: 0;
    padding: 0.65em 0.6em;
    width: 100%;
    border: 2px solid #d9c6a4;
    font-family: "futura-pt-bold", sans-serif;
    font-size: 1em;
  }

  &__button {
    display: block;
    width: 100%;
    padding: 0.78em 1em;
    background-color: #d9c6a4;
    color: #000;
    font-family: "futura-pt-bold", sans-serif;
    font-size: 1em;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    line-height: normal;
    -webkit-appearance: none;
    border: none;
    outline: none;
  }

  &__output {
    display: block;
    width: 100%;
    margin: 1em 0 0 0;
    padding: 1.1em 0.25em;
    font-size: 1.25em;
    line-height: 0;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 0.1em solid #000;
  }
}
