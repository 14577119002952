.sign-up {
  background-color: #fff;

  .nav-tabs {
    border-bottom: none;
    display: block;
    margin-top: 30px;
    li {
      display: block;
      &:first-child {
        margin-bottom: 10px;
      }
      a {
        font-size: 13px;
        font-weight: bold;
        &:active,
        &.active {
          background-color: #c6c6c6;
          border: 1px solid #c6c6c6;
          color: #fff;
        }
      }
    }
  }
  .btn-primary:not(:disabled):not(.disabled).active {
    background-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    color: #fff;
  }
}

.sign-up__inner {
  padding: 0 50px;
}

.sign-up__form {
  .btn,
  label {
    font-size: 13px;
    font-weight: bold;
  }
}

@mixin mq {
  .sign-up {
    .nav-tabs {
      display: flex;
      justify-content: space-between;
      li {
        flex-basis: 49%;
        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@mixin mq {
  .sign-up {
    background-color: transparent;
  }
  .sign-up__inner {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 25px 60px 35px;
    border-radius: 3px;
  }
}
