@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_font";
@import "../../frontend/stylesheets/styles/_mixins";

.order-list-item {
  .order-list-item__address__detail {
    .table-02 {
      tr:last-child {

        th,
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.order-list-item__address__head {
  color: $font-black;
  background-color: $blue-gray;
  padding: 12px;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;

  &::before {
    @mixin iconFont {
      content: "\f107";
    }

    margin-right: 8px;
  }
}

/* status tag */

.status-ordered,
.status-shipped,
.status-accepted,
.status-cancelled {
  text-align: center;
  padding: 4px 10px;
  color: #fff;
  font-size: 13px;
}

.status-ordered {
  background-color: $base-orange;
}

.status-shipped {
  background-color: $primary-color;
}

.status-accepted {
  background-color: $secondary-color;
}

.status-cancelled {
  background-color: $darkgrey;
}
