@import "../../frontend/stylesheets/styles/_variables";
@import "../../frontend/stylesheets/styles/_mixins";

.order-form {
  padding: 0 20px;

  .col-md-4 {
    margin-bottom: 30px;
  }

  .form-control {
    margin-bottom: 5px;
  }

  .form-group {
    &.address {
      .form-control {
        margin-bottom: 5px;
      }

      .zip {
        position: relative;

        .zip-mark {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 1em;
          height: 1.6em;
        }

        .form-control {
          display: inline;
          margin-left: 1.4em;
        }
      }
    }
  }

  .c-select-wrap__inner {
    .form-control {
      padding: 3px 20px;
    }
  }
}

.order-form__detail {
  table-layout: fixed;

  th {
    width: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  tr:last-child {
    border-top: 2px solid $line-gray;

    th,
    td {
      border-bottom: none;
    }
  }
}

@mixin mq {
  .order-form {
    .form-control {
      margin-bottom: 0;
    }

    width: 576px;
  }
}

@mixin mq {
  .order-form {
    padding: 0;
  }
}
