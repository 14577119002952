.regulation {
  h2.title-h2 {
    margin: 45px 0 30px;
    padding: 0;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    color: #1f1e1e;
    background: none;
  }

  section {
    margin: 30px 0;

    &:last-child {
      padding-bottom: 50px;
    }
  }

  p,ol,li {
    font-size: 12px;
  }

  h6 {
    margin-top: 10px;
    margin-bottom: 10px;

    &.heading {
      font-weight: 700;
    }
  }
}
